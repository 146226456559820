import EventBus from "eventing-bus";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Logo from "../../assets/img/greencrosslogo.png";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
} from "reactstrap";

import "./index.css";
import { logout } from "../../store/actions/Auth";
import { web3 } from "../../store/web3";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent",
      role: localStorage.getItem("role"),
    };
  }
  
  async componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white",
      });
    } else {
      this.setState({
        color: "navbar-transparent",
      });
    }
  };
  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent",
      });
    } else {
      this.setState({
        color: "bg-white",
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch,
    });
  };

  // logout = () => {
  //   this.props.logout();
  //   this.props.history.push("/");
  // };

  copied = () => EventBus.publish("success", "Address is Copied");

  render() {
    let { role } = this.state;
    let { address, balance } = this.props;
    return (
      <div className="navBarTop">
        <div className="super-admin-head">
          <div className="super-admin">
            <span>User Admin</span>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = { logout };

const mapStateToProps = ({ Auth }) => {
  let { role, balance, address } = Auth;
  return { role, balance, address };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
