import LotteryDetails from "./views/LotteryDetails/index.js";
import Profile from "./views/Profile/index.js";
import WinnersList from "./views/WinnersList/index.js";

var routes = [
  {
    layout: "/home",
    path: "/",
    component: LotteryDetails,
    hidden: true,
  },
  {
    layout: "/home",
    path: "/LotteryDetails",
    name: "Lottery Details",
    component: LotteryDetails,
    img: "/images/lot-detail.png",
    activeImg: "/images/lot-detail.png",
  },
  {
    layout: "/home",
    path: "/WinnersList",
    name: "Winners List",
    component: WinnersList,
    img: "/images/ranking.png",
    activeImg: "/images/ranking.png",
  },
  // {
  //   layout: "/home",
  //   path: "/Profile",
  //   name: "Profile",
  //   component: Profile,
  //   img: "/images/kyc-user.png",
  //   activeImg: "/images/kyc-user.png",
  // },
];

export default routes;