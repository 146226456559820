/*========== LOGIN ACTIONS ============= */
export const login = (data) => ({
      type: 'LOGIN',
      payload: data,
    });

export const setLoginData = (data) => ({
  type: 'SET_LOGIN_DATA',
  payload: data,
});

export const toggleLogin = () => ({
  type: 'TOGGLE_LOGIN',
});

export const isloginDisabled = () => ({
  type: 'IS_LOGIN_DISABLED',
});

export const logout = () => ({
  type: 'LOGOUT'
});

export const setAddress = (data) => ({
  type: 'SET_ADDRESS',
  payload: data
});

/*========== PAGE LOADER ACTIONS ============= */

export const setLoader = (data) => ({
  type: 'SET_LOADER',
  payload: data,
});

/*========== DEPLOY CONTRACT ACTIONS ============= */

export const deployLottery = (data) => ({
  type: 'DEPLOY_LOTTERY',
  payload: data,
});

export const getLottery = (data) => ({
  type: 'GET_LOTTERY',
  payload: data,
});

export const setLottery = (data) => ({
  type: 'SET_LOTTERY',
  payload: data,
});

export const getKybStatus = (data) => ({
  type: 'GET_KYB_STATUS',
  payload: data
});

export const setKybStatus = (data) => ({
  type: 'SET_KYB_STATUS',
  payload: data
});

export const getUriData = (data) => ({
  type: 'GET_URI_DATA',
  payload: data,
});

export const setUriData = (data) => ({
  type: 'SET_URI_DATA',
  payload: data,
});