import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import React, { Component } from 'react'
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from 'react-toastify';
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import Login from "./views/Login/index.js";
import Admin from "./layouts/Admin.jsx";
import PrivateRoute from './store/PrivateRoute';

import { logout, setAddress } from './store/actions/Auth';
import { web3 } from "./store/web3";
import { networkId, message } from "./store/config";
import { Token } from './store/contract/index';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const hist = createBrowserHistory();

export class App extends Component {

  async componentDidMount() {
    this.handleWalletChanges();
    EventBus.on('info', (e) => toast.info(e));
    EventBus.on('error', (e) => toast.error(e));
    EventBus.on('success', (e) => toast.success(e));
    EventBus.on("tokenExpired", () => this.props.logout());
    let address = (await web3.givenProvider.enable())[0];
    this.props.setAddress(address)
    // let balanceOf = await Token.methods.balanceOf(address).call();
    // let balance = web3.utils.fromWei(balanceOf.toString(), 'ether')
  };

handleWalletChanges = () => {
    let { auth } = this.props;
    if (!window.ethereum) {
      EventBus.publish("error", "Please install xdc extension!");
      return;
    };
    if (typeof window.ethereum !== "undefined") {
      // check network
      web3.eth.net.getId((err, netId) => {
        if (netId != networkId) EventBus.publish('info', message);
      });

      // if (window.ethereum.currentProvider.isMetaMask) {
      window.ethereum.on("accountsChanged", accounts => {
        this.props.logout();
        window.location.reload();
        EventBus.publish("info", "Account has been changed");
      });

      window.ethereum.on("networkChanged", netId => {
        // if (netId != networkId) {
        this.props.logout();
        window.location.reload();
        EventBus.publish("info", "Network has been changed");
        // }
      });
    // }
  };
}

  render() {
    return (
      <div>
        <ToastContainer />
        <Router history={hist}>
          <Switch>
            {this.props.isLogin ?
            <PrivateRoute path="/home" component={props => <Admin {...props} />} />
            :
            <Route path="/login" render={props => <Login {...props} />} />
            }
            {/* <Route path="/home" component={props => <Admin {...props} />} /> */}
            {!this.props.isLogin && <Redirect from="/" to="/login" /> }
            {this.props.isLogin && <Redirect from="/" to="/home" /> }
          </Switch>
        </Router>
      </div>
    )
  }
}
const mapDispatchToProps = { logout, setAddress };

const mapStateToProps = ({ Auth }) => {
  let { auth,isLogin, registerData } = Auth
  return { auth,isLogin }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
