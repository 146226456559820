import { PURGE } from "redux-persist";
import { setToken } from "../axios";

var initialState = {
  isLogin: localStorage.getItem("token") ? true : false,
  auth: localStorage.getItem("token"),
  address: localStorage.getItem("address"),
  isLoader: { message: "Please Wait...", status: false },
  deployedLottery: [],
  kybStatus: false,
  uriData: [],
};


const Auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    /*========== LOGIN REDUCERS ============= */

    case "SET_LOGIN_DATA":
      localStorage.setItem("token", payload);
      return {
        ...state,
        auth: payload,
        isLogin: true,
      };

    case "IS_LOGIN_DISABLED":
      return {
        ...state,
      };

    case "LOGOUT":
      setToken();
      localStorage.removeItem("token");
      localStorage.removeItem("address");
      return {
        ...state,
        address: "",
        auth: "",
        isLogin: !state.isLogin,
      };

    case "SET_ADDRESS":
      localStorage.setItem("address", payload);
      return {
        ...state,
        address: payload,
      };

    /*========== LOADER REDUCERS ============= */

    case "SET_LOADER":
      return {
        ...state,
        isLoader: payload,
      };

    /*========== ACCESS REDUCERS ============= */

    case "SET_KYB_STATUS":
      return {
        ...state,
        kybStatus: payload,
      };

    /*========== DEPLOY CONTRACT REDUCERS ============= */
    case 'SET_LOTTERY':
      return {
        ...state,
        deployedLottery: payload
      }

    case "SET_URI_DATA":
      return {
        ...state,
        uriData: payload,
      };

    default:
      return state;
  }
};

export default Auth;
