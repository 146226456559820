import moment from "moment";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { web3 } from "../../store/web3.js";
import React, { useEffect, useState } from 'react';
import { validate } from "wallet-address-validator";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { setLoader, deployLottery, getLottery } from "../../store/actions/Auth.js";
import { Subscription, SubscriptionAddress, CIFIToken, CIFITokenABI, NFTABI, NFTBytecode, LotteryABI, LotteryBytecode } from "../../store/contract/index.js";
import { ApiUrl, networkId } from "../../store/config.js";
import copyicon from "../../assets/img/copy.png";

import './index.css';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const LotteryDetails = (props) => {

  let [lotteryData, setlotteryData] = useState({});
  let [ticketPrice, setticketPrice] = useState("");
  let [xrcToken, setxrcToken] = useState("");
  let [iterations, setiterations] = useState("");
  let [ownerPercentage, setownerPercentage] = useState("");
  let [winnerPercentage, setwinnerPercentage] = useState("");
  let [monthlyLottery, setmonthlyLottery] = useState(true);
  let [topupAmount, settopupAmount] = useState("");
  let [topupPool, settopupPool] = useState(false);
  let [liquidityAmount, setliquidityAmount] = useState("");

  let [tokenName, settokenName] = useState("");
  let [tokenSymbol, settokenSymbol] = useState("");
  let [tokenSupply, settokenSupply] = useState("");
  let [tokenBanner, settokenBanner] = useState("");
  let [tokenUri, settokenUri] = useState("");
  let [tokenAddress, settokenAddress] = useState("");
  let [lotteryAddress, setlotteryAddress] = useState("");


  const [lotteryParametersModal, setLotteryParametersModal] = useState(false);
  const [createLotteryModal, setCreateLotteryModal] = useState(false);
  const [addLiquidityModal, setAddLiquidityModal] = useState(false);


  const handleOnWheel = (event) => {
    const { type } = event.target;
    if (type === 'number') {
      event.preventDefault();
    }
  }

  useEffect(() => {
    document.addEventListener('wheel', handleOnWheel, { passive: false });
    return () => {
      document.removeEventListener('wheel', handleOnWheel);
    };
  }, []);

  useEffect(() => {
    if (props.address) {
      props.getLottery({ owner: props.address });
    }
  }, [props.address]);

  useEffect(() => {
    if (web3 && props.address && props.deployedLottery && props.deployedLottery.length > 0) {
      sortLottery();
    }
  }, [props.deployedLottery, web3, props.address]);

  async function sortLottery() {
    try {
      let { address, deployedLottery } = props;
      props.setLoader({
        message: "Loading Lottery Data...",
        status: true,
      });
      let data = deployedLottery[0];
      let lotteryContract = new web3.eth.Contract(LotteryABI, data['lotteryAddress']);
      let tokenContract = new web3.eth.Contract(CIFITokenABI, data['paymentToken']);
      let NFTContract = new web3.eth.Contract(NFTABI, data['NFTAddress']);
      let lotteryEnded = await lotteryContract.methods.lotteryEnded().call({ from: address });
      let nextCall = await lotteryContract.methods.nextCall().call({ from: address });
      let endTimestamp = await lotteryContract.methods.endTimestamp().call({ from: address });
      endTimestamp = parseInt(endTimestamp);
      let iterations = await lotteryContract.methods.iterations().call({ from: address });
      iterations = parseInt(iterations);
      let iterationsCompleted = await lotteryContract.methods.iterationsCompleted().call({ from: address });
      iterationsCompleted = parseInt(iterationsCompleted);
      let iterationsLeft = iterations - iterationsCompleted;
      let contractXDCBalance = await lotteryContract.methods.contractXDCBalance().call({ from: address });
      contractXDCBalance = web3.utils.fromWei(contractXDCBalance.toString(), "ether");
      let paymentTokenSymbol = "XDC"
      if (data['paymentToken'] !== "0x0000000000000000000000000000000000000000") {
        paymentTokenSymbol = await tokenContract.methods.symbol().call({ from: address });
      }
      let contractXRC20Balance = 0;
      if (data['paymentToken'] !== "0x0000000000000000000000000000000000000000") {
        contractXRC20Balance = await lotteryContract.methods.contractXRC20Balance().call({ from: address });
        contractXRC20Balance = web3.utils.fromWei(contractXRC20Balance.toString(), "ether");
      }
      let totalBalance = parseInt(contractXRC20Balance) + parseInt(contractXDCBalance);
      let minted = await NFTContract.methods.totalSupply().call({ from: address });
      let createdAt = Date.parse(data['createdAt']) / 1000;
      let now = parseInt(Date.now() / 1000);
      setlotteryData({
        ...data, nextCall, contractXDCBalance, contractXRC20Balance, minted, createdAt, endTimestamp, now, iterations, iterationsCompleted,
        iterationsLeft, paymentTokenSymbol, lotteryEnded, totalBalance
      });
      props.setLoader({
        message: "Loading Lottery Data...",
        status: false,
      });
    } catch (error) {
      props.setLoader({
        message: "Loading Lottery Data...",
        status: false,
      });
    }
  }

  async function handleOnInput(e) {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    if ([e.target.name] == "ticketPrice") {
      if (e.target.value !== "" && parseFloat(e.target.value) > 0) {
        setticketPrice(parseFloat(e.target.value));
      } else {
        setticketPrice("");
      }
    } else if ([e.target.name] == "iterations") {
      if (e.target.value !== "" && parseInt(e.target.value) > 0) {
        setiterations(parseInt(e.target.value));
      } else {
        setiterations("");
      }
    } else if ([e.target.name] == "ownerPercentage") {
      if (e.target.value !== "" && parseInt(e.target.value) > 0) {
        setownerPercentage(parseInt(e.target.value));
        setwinnerPercentage(100 - (parseInt(e.target.value)))
      } else {
        setownerPercentage("");
        setwinnerPercentage("");
      }
    } else if ([e.target.name] == "monthlyLottery") {
      if (e.target.value == "true") {
        setmonthlyLottery(true);
      } else {
        setmonthlyLottery(false);
      }
    } else if ([e.target.name] == "topupPool") {
      settopupPool(!topupPool);
    } else if ([e.target.name] == "topupAmount") {
      if (parseFloat(e.target.value) > 0) {
        settopupAmount(parseFloat(e.target.value));
      } else {
        settopupAmount("");
      }
    } else if ([e.target.name] == "xrcToken") {
      let input = e.target.value;
      let output = input.substring(0, 3); // checks first three char of address
      if (output == "xdc") {
        let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
        setxrcToken(result);
      } else {
        setxrcToken(e.target.value);
      }
    } else if ([e.target.name] == "tokenName") {
      settokenName(e.target.value);
    } else if ([e.target.name] == "tokenSymbol") {
      settokenSymbol(e.target.value);
    } else if ([e.target.name] == "tokenUri") {
      settokenUri(e.target.value);
    } else if ([e.target.name] == "tokenSupply") {
      if (e.target.value !== "" && parseFloat(e.target.value) > 0) {
        settokenSupply(parseFloat(e.target.value));
      } else {
        settokenSupply("");
      }
    } else if ([e.target.name] == "tokenBanner") {
      settokenBanner(e.target.files[0]);
    } else if ([e.target.name] == "liquidityAmount") {
      if (parseFloat(e.target.value) > 0) {
        setliquidityAmount(parseFloat(e.target.value));
      } else {
        setliquidityAmount("");
      }
    }
  }

  function nextStep(e) {
    e.preventDefault();
    setLotteryParametersModal(false);
    setCreateLotteryModal(true);
  }

  function reverseStep() {
    setLotteryParametersModal(true);
    setCreateLotteryModal(false);
  }

  async function getIPFSData(url) {
    return new Promise(async (resolve, reject) => {
      try {
        if (url && !url.endsWith("/")) url = url + "/";
        let data = await fetch(`${ApiUrl}/v1/validateURI`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Set the content type to JSON if you're sending JSON data
          },
          body: JSON.stringify({ url: `${url}1` }), // Convert your data to JSON format if needed
        });
        if (parseInt(data.status) == 200) {
          data = await data.json();
          return resolve(true);
        }
        if (parseInt(data.status) == 400) {
          let data = await fetch(`${ApiUrl}/v1/validateURI`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json', // Set the content type to JSON if you're sending JSON data
            },
            body: JSON.stringify({ url: `${url}1.json` }), // Convert your data to JSON format if needed
          });
          if (parseInt(data.status) == 200) {
            return resolve(true);
          } else if (parseInt(data.status) == 400) {
            let data = await fetch(`${ApiUrl}/v1/validateURI`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json', // Set the content type to JSON if you're sending JSON data
              },
              body: JSON.stringify({ url }), // Convert your data to JSON format if needed
            });
            if (parseInt(data.status) == 200) {
              return resolve(true);
            }
            else return resolve(false);
          }
        }
      } catch (e) {
        return resolve(false);
      }
    });
  }

  async function deploy(e) {
    try {

      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { address } = props;

      let paymentToken = "";

      if (iterations == "") {
        EventBus.publish("error", `Please enter lottery iterations`);
        return;
      }

      if (ownerPercentage == "") {
        EventBus.publish("error", `Please enter lottery owner distribution`);
        return;
      }

      if (winnerPercentage == "") {
        EventBus.publish("error", `Please enter lottery winner distribution`);
        return;
      }

      if (tokenName == "") {
        EventBus.publish("error", `Please enter token name`);
        return;
      }

      if (!tokenName.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter token name`);
        return;
      }

      if (!tokenName.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Token name must contain alphabets`);
        return;
      }

      if (tokenSymbol == "") {
        EventBus.publish("error", `Please enter token symbol`);
        return;
      }

      if (!tokenSymbol.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter token symbol`);
        return;
      }

      if (!tokenSymbol.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Token symbol must contain alphabets`);
        return;
      }

      if (tokenSupply == "") {
        EventBus.publish("error", `Please enter token supply`);
        return;
      }

      if (tokenUri == "") {
        EventBus.publish("error", `Please enter base URI`);
        return;
      }

      if (!tokenUri.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter base URI`);
        return;
      }

      if (!tokenUri.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Base URI must contain alphabets`);
        return;
      }

      let checkUrl = await getIPFSData(tokenUri);
      if (checkUrl == false) return EventBus.publish('error', `Invalid base URI`);

      if (ticketPrice == "") {
        EventBus.publish("error", `Please enter token price`);
        return;
      }

      if (xrcToken == "" || xrcToken == "0x0000000000000000000000000000000000000000") {
        setxrcToken("0x0000000000000000000000000000000000000000");
        paymentToken = "0x0000000000000000000000000000000000000000";
      } else {

        if (!xrcToken.replace(/\s/g, '').length) {
          EventBus.publish("error", `Invalid XRC-20 Token Address`);
          return;
        }

        if (!xrcToken.match(/^[a-zA-Z0-9]+$/)) {
          EventBus.publish("error", `Invalid XRC-20 Token Address`);
          return;
        }

        if (!validate(xrcToken, "ETH")) {
          EventBus.publish("error", `Invalid XRC-20 Token Address`);
          return;
        }

        let code = await web3.eth.getCode(xrcToken.toLowerCase());
        if (code === "0x") {
          EventBus.publish("error", `Invalid XRC-20 Token Address!`);
          return;
        } else {
          paymentToken = xrcToken;
        }
      }

      if (tokenBanner == "") {
        EventBus.publish("error", `Please upload token banner`);
        return;
      }

      if (tokenBanner == undefined) {
        EventBus.publish("error", `Please upload token banner`);
        return;
      }

      if (address == null || address == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      let from = address;
      let output = address.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        from = "0x" + address.substring(3);
      } else {
        from = address;
      }

      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      let sub = await Subscription.methods.subscribeLottery(from).call();
      if (!sub['status']) {
        props.setLoader({
          message: "Subscription in Progress...",
          status: true,
        });
        let balance = await CIFIToken.methods.balanceOf(from).call();
        let req = await Subscription.methods.lotterySubscriptionPrice().call();
        // let req1 = web3.utils.fromWei(req.toString(), 'ether');
        // setSubPrice(parseFloat(req1));
        if (parseInt(balance) < parseInt(req)) {
          setxrcToken("");
          EventBus.publish("error", `Don't have enough CIFI token to subscribe!`);
          props.setLoader({
            message: "Subscription Failed...",
            status: false,
          });
          return;
        } else {
          props.setLoader({
            message: "Subscription in Progress...",
            status: true,
          });
          await CIFIToken.methods.approve(SubscriptionAddress, req).send({ from: deployer });
          await Subscription.methods.lotterySubscription(req).send({ from: deployer });
          props.setLoader({
            message: "Subscription in Progress...",
            status: false,
          });
        }
      }

      // if (!tokenWallet.match(/^[a-zA-Z0-9]+$/)) {
      //   EventBus.publish("error", `Invalid Wallet Address`);
      //   return;
      // }

      // if (!validate(tokenWallet, "ETH")) {
      //   EventBus.publish("error", `Please provide valid wallet address`);
      //   return;
      // }

      props.setLoader({
        message: "Lottery Deployment In Progress...",
        status: true,
      });

      let price = web3.utils.toWei(ticketPrice.toString(), "ether");

      let tokenType = "XRC721"
      let lotteryAddress = ""

      let contract = new web3.eth.Contract(LotteryABI);
      let deploy = contract.deploy({ data: LotteryBytecode, arguments: [price, paymentToken, iterations, ownerPercentage, monthlyLottery] });

      await deploy.send({ from: deployer })
        .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
        .on('receipt', async receipt => {

          lotteryAddress = receipt['contractAddress'];
          setlotteryAddress(receipt['contractAddress']);

          if (topupPool == true) {
            if (paymentToken == "0x0000000000000000000000000000000000000000") {
              if (topupAmount == "") {
                EventBus.publish("error", `Please enter top-up amount`);
                return;
              }

              let balanceWei = await web3.eth.getBalance(deployer);
              let topupAmountWei = web3.utils.toWei(topupAmount.toString(), 'ether');
              if (parseInt(topupAmountWei) > parseInt(balanceWei)) {
                return EventBus.publish("error", `Insufficient XDC to add liquidity`);
              } else {
                props.setLoader({
                  message: "Adding Liquidity...",
                  status: true,
                });
                await web3.eth
                  .sendTransaction({
                    from: deployer,
                    to: receipt['contractAddress'],
                    gas: 5000000,
                    value: topupAmountWei
                  })
                  .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                  .on('receipt', async receipt => {
                    props.setLoader({
                      message: "NFT Deployment In Progress...",
                      status: true,
                    });

                    let contract1 = new web3.eth.Contract(NFTABI);
                    let deploy1 = contract1.deploy({ data: NFTBytecode, arguments: [tokenName, tokenSymbol, tokenSupply, tokenUri, price, paymentToken, lotteryAddress] });

                    await deploy1.send({ from: deployer })
                      .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                      .on('receipt', async receipt => {

                        let data = new FormData();
                        data.append('name', tokenName);
                        data.append('symbol', tokenSymbol);
                        data.append('tokenSupply', tokenSupply);
                        data.append('ticketPrice', ticketPrice);
                        data.append('paymentToken', paymentToken);
                        data.append('banner', tokenBanner);
                        data.append('ownerPercentage', ownerPercentage);
                        data.append('winnerPercentage', winnerPercentage);
                        data.append('monthlyLottery', monthlyLottery);
                        data.append('iterations', iterations);
                        data.append('network', parseInt(networkId));
                        data.append('lotteryAddress', lotteryAddress);
                        data.append('NFTAddress', receipt['contractAddress']);
                        data.append('owner', deployer);
                        await props.deployLottery(data);

                        EventBus.publish("success", `Deployed: ${receipt['contractAddress']}`);
                        waitFor(1500);
                        settokenAddress(receipt['contractAddress']);
                        setticketPrice("");
                        setxrcToken("");
                        setiterations("");
                        setownerPercentage("");
                        setwinnerPercentage("");
                        setmonthlyLottery(true);
                        settopupAmount("");
                        settopupPool(false);
                        settokenName("");
                        settokenSymbol("");
                        settokenSupply("");
                        settokenBanner("");
                        settokenUri("");
                        props.getLottery({ owner: deployer });
                        props.setLoader({
                          message: "NFT Deployment In Progress...",
                          status: false,
                        });
                        console.log(`************** deploy contract address = `, receipt['contractAddress'])
                      });
                  });
              }
            } else {
              if (topupAmount == "") {
                EventBus.publish("error", `Please enter top-up amount`);
                return;
              }

              let contractToken = new web3.eth.Contract(CIFITokenABI, paymentToken);
              let balanceWei = await contractToken.methods.balanceOf(deployer).call();
              let symbol = await contractToken.methods.symbol().call();
              let topupAmountWei = web3.utils.toWei(topupAmount.toString(), 'ether');
              if (parseInt(topupAmountWei) > parseInt(balanceWei)) {
                return EventBus.publish("error", `Insufficient ${symbol} to add liquidity`);
              } else {
                props.setLoader({
                  message: "Approving Tokens...",
                  status: true,
                });
                await web3.eth
                  .sendTransaction({
                    from: deployer,
                    to: paymentToken,
                    gas: 5000000,
                    data: contractToken.methods
                      .approve(lotteryAddress, topupAmountWei)
                      .encodeABI(),
                  })
                  .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                  .on('receipt', async receipt => {
                    props.setLoader({
                      message: "Adding Liquidity...",
                      status: true,
                    });
                    await web3.eth
                      .sendTransaction({
                        from: deployer,
                        to: paymentToken,
                        gas: 5000000,
                        data: contractToken.methods
                          .transfer(lotteryAddress, topupAmountWei)
                          .encodeABI(),
                      })
                      .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                      .on('receipt', async receipt => {
                        props.setLoader({
                          message: "NFT Deployment In Progress...",
                          status: true,
                        });

                        let contract1 = new web3.eth.Contract(NFTABI);
                        let deploy1 = contract1.deploy({ data: NFTBytecode, arguments: [tokenName, tokenSymbol, tokenSupply, tokenUri, price, paymentToken, lotteryAddress] });

                        await deploy1.send({ from: deployer })
                          .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                          .on('receipt', async receipt => {

                            let data = new FormData();
                            data.append('name', tokenName);
                            data.append('symbol', tokenSymbol);
                            data.append('tokenSupply', tokenSupply);
                            data.append('ticketPrice', ticketPrice);
                            data.append('paymentToken', paymentToken);
                            data.append('banner', tokenBanner);
                            data.append('ownerPercentage', ownerPercentage);
                            data.append('winnerPercentage', winnerPercentage);
                            data.append('monthlyLottery', monthlyLottery);
                            data.append('iterations', iterations);
                            data.append('network', parseInt(networkId));
                            data.append('lotteryAddress', lotteryAddress);
                            data.append('NFTAddress', receipt['contractAddress']);
                            data.append('owner', deployer);
                            await props.deployLottery(data);

                            EventBus.publish("success", `Deployed: ${receipt['contractAddress']}`);
                            waitFor(1500);
                            settokenAddress(receipt['contractAddress']);
                            setticketPrice("");
                            setxrcToken("");
                            setiterations("");
                            setownerPercentage("");
                            setwinnerPercentage("");
                            setmonthlyLottery(true);
                            settopupAmount("");
                            settopupPool(false);
                            settokenName("");
                            settokenSymbol("");
                            settokenSupply("");
                            settokenBanner("");
                            settokenUri("");
                            props.getLottery({ owner: deployer });
                            props.setLoader({
                              message: "NFT Deployment In Progress...",
                              status: false,
                            });
                            console.log(`************** deploy contract address = `, receipt['contractAddress'])
                          });

                      });
                  });
              }
            }
          } else {
            props.setLoader({
              message: "NFT Deployment In Progress...",
              status: true,
            });

            let contract1 = new web3.eth.Contract(NFTABI);
            let deploy1 = contract1.deploy({ data: NFTBytecode, arguments: [tokenName, tokenSymbol, tokenSupply, tokenUri, price, paymentToken, receipt['contractAddress']] });

            await deploy1.send({ from: deployer })
              .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
              .on('receipt', async receipt => {

                let data = new FormData();
                data.append('name', tokenName);
                data.append('symbol', tokenSymbol);
                data.append('tokenSupply', tokenSupply);
                data.append('ticketPrice', ticketPrice);
                data.append('paymentToken', paymentToken);
                data.append('banner', tokenBanner);
                data.append('ownerPercentage', ownerPercentage);
                data.append('winnerPercentage', winnerPercentage);
                data.append('monthlyLottery', monthlyLottery);
                data.append('iterations', iterations);
                data.append('network', parseInt(networkId));
                data.append('lotteryAddress', lotteryAddress);
                data.append('NFTAddress', receipt['contractAddress']);
                data.append('owner', deployer);
                await props.deployLottery(data);

                EventBus.publish("success", `Deployed: ${receipt['contractAddress']}`);
                waitFor(1500);
                settokenAddress(receipt['contractAddress']);
                setticketPrice("");
                setxrcToken("");
                setiterations("");
                setownerPercentage("");
                setwinnerPercentage("");
                setmonthlyLottery(true);
                settopupAmount("");
                settopupPool(false);
                settokenName("");
                settokenSymbol("");
                settokenSupply("");
                settokenBanner("");
                settokenUri("");
                props.getLottery({ owner: deployer });
                props.setLoader({
                  message: "NFT Deployment In Progress...",
                  status: false,
                });
                console.log(`************** deploy contract address = `, receipt['contractAddress'])
              });
          }
        });
    } catch (e) {
      console.log(e);
      setxrcToken("");
      props.setLoader({
        message: "Deployment Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To Deploy`);
    }
  };

  async function execute() {
    try {
      let deployer = (await web3.currentProvider.enable())[0];

      if (deployer == null || deployer == undefined) {
        EventBus.publish("error", `Please connect your metamask!`);
        return;
      }

      let primaryContract = new web3.eth.Contract(LotteryABI, lotteryData['lotteryAddress']);

      props.setLoader({
        message: "Executing Lottery",
        status: true,
      });

      let result = await primaryContract.methods.runLottery();
      await web3.eth
        .sendTransaction({
          from: deployer,
          to: lotteryData['lotteryAddress'],
          gas: 5000000,
          data: result.encodeABI(),
        })
        .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
        .on("receipt", async (receipt) => {
          sortLottery();
          props.setLoader({ status: false });
          EventBus.publish("success", `Lottery Executed Successfully`);
        });
    } catch (e) {
      props.setLoader({
        message: "Lottery Execution Failed!",
        status: false,
      });
      EventBus.publish("error", `Lottery Execution Failed!`);
    }
  }

  async function end() {
    try {
      let deployer = (await web3.currentProvider.enable())[0];

      if (deployer == null || deployer == undefined) {
        EventBus.publish("error", `Please connect your metamask!`);
        return;
      }

      let primaryContract = new web3.eth.Contract(LotteryABI, lotteryData['lotteryAddress']);

      props.setLoader({
        message: "Ending Lottery",
        status: true,
      });

      let result = await primaryContract.methods.endLottery();
      await web3.eth
        .sendTransaction({
          from: deployer,
          to: lotteryData['lotteryAddress'],
          gas: 5000000,
          data: result.encodeABI(),
        })
        .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
        .on("receipt", async (receipt) => {
          sortLottery();
          props.setLoader({ status: false });
          EventBus.publish("success", `Lottery Ended Successfully`);
        });
    } catch (e) {
      props.setLoader({
        message: "Lottery Ending Failed!",
        status: false,
      });
      EventBus.publish("error", `Lottery Ending Failed!`);
    }
  }

  async function flush() {
    try {
      let deployer = (await web3.currentProvider.enable())[0];

      if (deployer == null || deployer == undefined) {
        EventBus.publish("error", `Please connect your metamask!`);
        return;
      }

      let primaryContract = new web3.eth.Contract(LotteryABI, lotteryData['lotteryAddress']);

      props.setLoader({
        message: "Flushing Lottery",
        status: true,
      });

      let result = await primaryContract.methods.flushContract();
      await web3.eth
        .sendTransaction({
          from: deployer,
          to: lotteryData['lotteryAddress'],
          gas: 5000000,
          data: result.encodeABI(),
        })
        .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
        .on("receipt", async (receipt) => {
          sortLottery();
          props.setLoader({ status: false });
          EventBus.publish("success", `Lottery Flushed Successfully`);
        });
    } catch (e) {
      props.setLoader({
        message: "Lottery Flushing Failed!",
        status: false,
      });
      EventBus.publish("error", `Lottery Flushing Failed!`);
    }
  }

  async function fillPool() {
    try {
      let deployer = (await web3.currentProvider.enable())[0];

      if (deployer == null || deployer == undefined) {
        EventBus.publish("error", `Please connect your metamask!`);
        return;
      }

      if (lotteryData['paymentToken'] == "0x0000000000000000000000000000000000000000") {
        if (liquidityAmount == "") {
          EventBus.publish("error", `Please enter top-up amount`);
          return;
        }

        let balanceWei = await web3.eth.getBalance(deployer);
        let liquidityAmountWei = web3.utils.toWei(liquidityAmount.toString(), 'ether');
        if (parseInt(liquidityAmountWei) > parseInt(balanceWei)) {
          return EventBus.publish("error", `Insufficient XDC to add liquidity`);
        } else {
          props.setLoader({
            message: "Adding Liquidity...",
            status: true,
          });
          await web3.eth
            .sendTransaction({
              from: deployer,
              to: lotteryData['lotteryAddress'],
              gas: 5000000,
              value: liquidityAmountWei
            })
            .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
            .on('receipt', async receipt => {
              sortLottery();
              props.setLoader({ status: false });
              setAddLiquidityModal(false);
              EventBus.publish("success", `Liquidity Added Successfully`);
            });
        }
      } else {
        if (liquidityAmount == "") {
          EventBus.publish("error", `Please enter top-up amount`);
          return;
        }

        let contractToken = new web3.eth.Contract(CIFITokenABI, lotteryData['paymentToken']);
        let balanceWei = await contractToken.methods.balanceOf(deployer).call();
        let symbol = await contractToken.methods.symbol().call();
        let liquidityAmountWei = web3.utils.toWei(liquidityAmount.toString(), 'ether');
        if (parseInt(liquidityAmountWei) > parseInt(balanceWei)) {
          return EventBus.publish("error", `Insufficient ${symbol} to add liquidity`);
        } else {
          props.setLoader({
            message: "Approving Tokens...",
            status: true,
          });
          await web3.eth
            .sendTransaction({
              from: deployer,
              to: lotteryData['paymentToken'],
              gas: 5000000,
              data: contractToken.methods
                .approve(lotteryData['lotteryAddress'], liquidityAmountWei)
                .encodeABI(),
            })
            .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
            .on('receipt', async receipt => {
              props.setLoader({
                message: "Adding Liquidity...",
                status: true,
              });
              await web3.eth
                .sendTransaction({
                  from: deployer,
                  to: lotteryData['paymentToken'],
                  gas: 5000000,
                  data: contractToken.methods
                    .transfer(lotteryData['lotteryAddress'], liquidityAmountWei)
                    .encodeABI(),
                })
                .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                  sortLottery();
                  props.setLoader({ status: false });
                  setAddLiquidityModal(false);
                  EventBus.publish("success", `Liquidity Added Successfully`);
                });
            });
        }
      }
    } catch (e) {
      props.setLoader({
        message: "Adding Liquidity Failed!",
        status: false,
      });
      EventBus.publish("error", `Adding Liquidity Failed!`);
    }
  }

  async function copiedAddress() {
    EventBus.publish("success", "Contract Address Copied");
  }

  // console.log("*** ticketPrice :: ", ticketPrice);
  // console.log("*** xrcToken :: ", xrcToken);
  // console.log("*** iterations :: ", iterations);
  // console.log("*** ownerPercentage :: ", ownerPercentage);
  // console.log("*** winnerPercentage :: ", winnerPercentage);
  // console.log("*** monthlyLottery :: ", monthlyLottery);
  // console.log("*** topupAmount :: ", topupAmount);
  // console.log("*** topupPool :: ", topupPool);

  // console.log("*** tokenName :: ", tokenName);
  // console.log("*** tokenSymbol :: ", tokenSymbol);
  // console.log("*** tokenSupply :: ", tokenSupply);
  // console.log("*** tokenUri :: ", tokenUri);

  console.log("*** deployedLottery :: ", props.deployedLottery);
  console.log("*** lotteryData :: ", lotteryData);

  return (
    <div className="content">
      <div className="main-container mint-new">
        <div className="lottery-details-wrap">
          <div className="lottery-details">
            <div className="inner">
              {
                lotteryData && Object.keys(lotteryData).length > 0 ?
                  <img src={lotteryData['banner']} />
                  :
                  <img src="images/alternate.jpg" />
              }

              <div className="info">
                <h3>Lottery details</h3>

                {
                  lotteryData && Object.keys(lotteryData).length > 0 ?
                    <p>{lotteryData['name']}</p>
                    :
                    <p>Please recheck all the necessary information about your lottery, including prizes, rules, and draw dates, to attract participants.</p>
                }

                <div className="info-boxes-wrap">
                  <div className="info-box">
                    <span>Minted</span>

                    {
                      lotteryData && Object.keys(lotteryData).length > 0 ?
                        <p>{lotteryData['minted']}/{lotteryData['tokenSupply']}</p>
                        :
                        <p>N/A</p>
                    }
                  </div>

                  <div className="info-box">
                    <span>Price</span>

                    {
                      lotteryData && Object.keys(lotteryData).length > 0 ?
                        <>
                          {lotteryData['paymentToken'] !== "0x0000000000000000000000000000000000000000" ?
                            <p>{lotteryData['ticketPrice']} {lotteryData['paymentTokenSymbol']} </p>
                            :
                            <p>{lotteryData['ticketPrice']} XDC </p>
                          }
                        </>
                        :
                        <p>N/A</p>
                    }
                  </div>

                  <div className="info-box">
                    <span>Date created</span>

                    {
                      lotteryData && Object.keys(lotteryData).length > 0 ?
                        <p>{moment.utc(parseInt(lotteryData['createdAt']) * 1000).local().format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
                        :
                        <p>N/A</p>
                    }
                  </div>

                  <div className="info-box">
                    <span>Up coming draw date</span>

                    {
                      lotteryData && Object.keys(lotteryData).length > 0 ?
                        <p>{moment.utc(parseInt(lotteryData['nextCall']) * 1000).local().format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
                        :
                        <p>N/A</p>
                    }
                  </div>

                  <div className="info-box">
                    <span>Pool value <img src="images/money-add.png" /></span>

                    {
                      lotteryData && Object.keys(lotteryData).length > 0 ?
                        <>
                          {lotteryData['contractXRC20Balance'] > 0 ? (
                            <p>{lotteryData['contractXRC20Balance']} {lotteryData['paymentTokenSymbol']} </p>
                          ) : lotteryData['contractXDCBalance'] > 0 ? (
                            <p>{lotteryData['contractXDCBalance']} XDC </p>
                          ) : (
                            <p>N/A</p>
                          )}
                        </>
                        :
                        <p>N/A</p>
                    }
                  </div>
                </div>
                {
                  lotteryData && Object.keys(lotteryData).length > 0 ?
                    <>
                      {
                        parseInt(lotteryData['iterationsLeft']) > 0 && (parseInt(lotteryData['now']) >= parseInt(lotteryData['nextCall'])) &&
                        parseInt(lotteryData['totalBalance']) > 0 && parseInt(lotteryData['minted']) > 0 &&
                        <button onClick={() => execute()} className="common-btn">Execute Lottery<img src="images/arrow-right.png" /></button>
                      }
                      {
                        (parseInt(lotteryData['now']) > parseInt(lotteryData['endTimestamp'])) && lotteryData['lotteryEnded'] == false &&
                        <button onClick={() => end()} className="common-btn">End Lottery<img src="images/arrow-right.png" /></button>
                      }
                      {/* <button onClick={() => flush()} className="common-btn">Flush Lottery<img src="images/arrow-right.png" /></button> */}
                      {
                        (parseInt(lotteryData['iterationsLeft']) > 0) && (parseInt(lotteryData['now']) < parseInt(lotteryData['endTimestamp'])) &&
                        <button onClick={() => setAddLiquidityModal(true)} className="common-btn">Add Liquidity<img src="images/arrow-right.png" /></button>
                      }
                      {
                        lotteryData['lotteryEnded'] == true &&
                        <button className="common-btn" disabled>Lottery Ended</button>
                      }
                    </>
                    :
                    <button onClick={() => setLotteryParametersModal(true)} className="common-btn">Create New Lottery<img src="images/arrow-right.png" /></button>
                }
              </div>
            </div>
          </div>
          {
            lotteryAddress !== "" &&
            <p>
              <CopyToClipboard
                text={lotteryAddress}
                onCopy={copiedAddress}
              >
                {networkId == 50 ? (
                  <a href={`https://xdc.blocksscan.io/address/${lotteryAddress}`} target="_blank">
                    {`Lottery Address: `}
                    <span> {lotteryAddress}<img className="go-arrow" src={copyicon} alt="Go Arrow" /></span>
                  </a>
                ) : networkId == 51 ? (
                  <a href={`https://apothem.blocksscan.io/address/${lotteryAddress}`} target="_blank">
                    {`Lottery Address: `}
                    <span> {lotteryAddress}<img className="go-arrow" src={copyicon} alt="Go Arrow" /></span>
                  </a>
                ) : (
                  <></>
                )}
              </CopyToClipboard>
            </p>
          }
          {
            tokenAddress !== "" &&
            <p>
              <CopyToClipboard
                text={tokenAddress}
                onCopy={copiedAddress}
              >
                {networkId == 50 ? (
                  <a href={`https://xdc.blocksscan.io/address/${tokenAddress}`} target="_blank">
                    {`NFT Ticket Address: `}
                    <span> {tokenAddress}<img className="go-arrow" src={copyicon} alt="Go Arrow" /></span>
                  </a>
                ) : networkId == 51 ? (
                  <a href={`https://apothem.blocksscan.io/address/${tokenAddress}`} target="_blank">
                    {`NFT Ticket Address: `}
                    <span> {tokenAddress}<img className="go-arrow" src={copyicon} alt="Go Arrow" /></span>
                  </a>
                ) : (
                  <></>
                )}
              </CopyToClipboard>
            </p>
          }
        </div>
      </div>


      <Modal
        open={lotteryParametersModal}
        onClose={() => setLotteryParametersModal(false)}
        classNames={{
          modal: `microeconomy-modal borrow-manage-eth-modal`
        }}
        center
      >
        <button className="close-btn absolute right-4 top-6">
          <img
            src="images/close.png"
            onClick={() => setLotteryParametersModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <div className="inner">
              <h2>Set Lottery Parameters</h2>

              <form onSubmit={nextStep}>
                <div className="form-group">
                  <input
                    type="number"
                    name="ownerPercentage"
                    value={ownerPercentage}
                    onChange={handleOnInput}
                    placeholder="Owner Lottery split %age"
                    onWheel={handleOnWheel}
                  />

                  <div className="info-wrap">
                    <img src="images/infocircle.png" alt="" />

                    <div className="info">

                      <p>Enter Owner Lottery Split %age Here</p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <input
                    type="number"
                    name="winnerPercentage"
                    value={winnerPercentage}
                    onChange={handleOnInput}
                    placeholder="Winner Lottery split %age"
                    onWheel={handleOnWheel}
                    disabled
                  />
                  <div className="info-wrap">
                    <img src="images/infocircle.png" alt="" />

                    <div className="info">

                      <p>Winner Lottery Split %age Here</p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <input
                    type="number"
                    name="iterations"
                    value={iterations}
                    onChange={handleOnInput}
                    placeholder="Lottery iterations"
                    onWheel={handleOnWheel}
                  />
                  <div className="info-wrap">
                    <img src="images/infocircle.png" alt="" />

                    <div className="info">

                      <p>Enter How Many Times Lottery Will Run</p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <select
                    name="monthlyLottery"
                    value={monthlyLottery}
                    onChange={handleOnInput}
                  >
                    <option default value={true}>Monthly</option>
                    <option value={false}>Quarterly</option>
                  </select>
                  <div className="info-wrap">
                    <img src="images/infocircle.png" alt="" />

                    <div className="info">

                      <p>Choose Monthly (or) Quarterly Lottery Here</p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    name="xrcToken"
                    value={xrcToken}
                    onChange={handleOnInput}
                    placeholder="XRC-20 address for lottery ticket (Leave empty for XDC)"
                  />
                  <div className="info-wrap">
                    <img src="images/infocircle.png" alt="" />

                    <div className="info">

                      <p>Enter XRC-20 Token Address For Lottery Ticket Here</p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <input
                    type="number"
                    name="ticketPrice"
                    value={ticketPrice}
                    onChange={handleOnInput}
                    placeholder="Lottery Ticket Price"
                    onWheel={handleOnWheel}
                  />
                  <div className="info-wrap">
                    <img src="images/infocircle.png" alt="" />

                    <div className="info">

                      <p>Enter Lottery Ticket Price Here</p>
                    </div>
                  </div>
                </div>

                <div className="form-group checkbox-wrap">
                  <input
                    type="checkbox"
                    checked={topupPool}
                    name="topupPool"
                    value={topupPool}
                    onChange={handleOnInput}
                  />
                  <label>Top up pool with XRC-20 or XDC</label>
                </div>

                {
                  topupPool == true &&
                  <div className="form-group">
                    <input
                      type="number"
                      name="topupAmount"
                      value={topupAmount}
                      onChange={handleOnInput}
                      placeholder="Enter amount"
                      onWheel={handleOnWheel}
                    />
                    <div className="info-wrap">
                      <img src="images/infocircle.png" alt="" />

                      <div className="info">
                        <p>Enter Lottery Topup Amount Here</p>
                      </div>
                    </div>
                  </div>
                }

                <button className="common-btn" type="submit">
                  Set Lottery Ticket
                </button>
              </form>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={createLotteryModal}
        onClose={() => setCreateLotteryModal(false)}
        classNames={{
          modal: `microeconomy-modal borrow-manage-eth-modal`
        }}
        center
      >
        <button className="close-btn absolute right-4 top-6">
          <img
            src="images/close.png"
            onClick={() => setCreateLotteryModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <div className="inner">
              <h2>Create Lottery Ticket</h2>

              <form onSubmit={deploy}>
                <div className="form-group filewrap">
                  <span>{tokenBanner ? (tokenBanner['name'] && tokenBanner['name'].substring(0, 20) + '...') : "Set Lottery Image"}</span>
                  <div className="upload-btn"><img src="images/arrowup.png" alt="Upload File Icon" />Upload Image</div>
                  <input
                    type="file"
                    name='tokenBanner'
                    placeholder={tokenBanner ? (tokenBanner['name'] && tokenBanner['name'].substring(0, 20) + '...') : "Set Lottery Image"}
                    onChange={handleOnInput}
                    accept="image/*"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    name="tokenName"
                    value={tokenName}
                    onChange={handleOnInput}
                    placeholder="Set Lottery Name"
                  />
                  <div className="info-wrap">
                    <img src="images/infocircle.png" alt="" />

                    <div className="info">
                      <p>Enter Lottery Name Here</p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    name="tokenSymbol"
                    value={tokenSymbol}
                    onChange={handleOnInput}
                    placeholder="Set Lottery Symbol"
                  />
                  <div className="info-wrap">
                    <img src="images/infocircle.png" alt="" />

                    <div className="info">
                      <p>Enter Lottery Symbol Here</p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <input
                    type="number"
                    name="tokenSupply"
                    value={tokenSupply}
                    onChange={handleOnInput}
                    placeholder="Total number of Lottery Tickets"
                    onWheel={handleOnWheel}
                  />
                  <div className="info-wrap">
                    <img src="images/infocircle.png" alt="" />

                    <div className="info">
                      <p>Enter Total Number Of Lottery Tickets Here</p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label>
                    <a href="https://dhegch1bc76pc.cloudfront.net/" target="_blank">Click here to generate your Base URI</a>
                  </label>
                  <input
                    type="text"
                    name="tokenUri"
                    value={tokenUri}
                    onChange={handleOnInput}
                    placeholder="Set Base URI"
                  />
                  <div className="info-wrap">
                    <img src="images/infocircle.png" alt="" />

                    <div className="info">
                      <p>Enter Lottery Ticket Base URI Here</p>
                    </div>
                  </div>
                </div>

                {/* <div className="form-group checkbox-wrap">
                  <input
                    type="checkbox"
                  />

                  <label>
                    Acknowledge Amet minim mollit non deserunt ullamco est sit aliqua dolor <a href="#">term of services</a> do amet sint. <a href="#">Privacy policy</a> Velit officia consequat duis enim velit mollit.
                  </label>
                </div> */}

                <button className="common-btn" onClick={reverseStep}>
                  Back
                </button>
                <br />
                <br />
                <button className="common-btn" type="submit">
                  Create Lottery
                </button>
              </form>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={addLiquidityModal}
        onClose={() => setAddLiquidityModal(false)}
        classNames={{
          modal: `microeconomy-modal borrow-manage-eth-modal`
        }}
        center
      >
        <button className="close-btn absolute right-4 top-6">
          <img
            src="images/close.png"
            onClick={() => setAddLiquidityModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <div className="inner">
              <h2>Add Liquidity</h2>

              <form onSubmit={fillPool}>

                <div className="form-group">
                  <input
                    type="number"
                    name="liquidityAmount"
                    value={liquidityAmount}
                    onChange={handleOnInput}
                    placeholder="Enter liquidity amount"
                    onWheel={handleOnWheel}
                  />
                  <div className="info-wrap">
                    <img src="images/infocircle.png" alt="" />

                    <div className="info">
                      <p>Enter Topup Liquidity Amount Here</p>
                    </div>
                  </div>
                </div>

                <button className="common-btn" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );

}

const mapDispatchToProps = {
  setLoader,
  deployLottery,
  getLottery
};

const mapStateToProps = ({ Auth }) => {
  let { address, deployedLottery } = Auth;
  return { address, deployedLottery };
};
export default connect(mapStateToProps, mapDispatchToProps)(LotteryDetails);