import axios from 'axios';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';
import { setToken } from "../axios";
import {
  setLoginData,
  setAddress,
  setLottery,
  setKybStatus,
  setUriData,
} from "../actions/Auth";


/*========== LOGIN FUNCTIONS =============*/

function* login({ payload, history }) {
  const { error, response } = yield call(postCall, { path: '/v3/loginAdmin', payload });
  if (error) {
    yield put({ type: "TOGGLE_LOGIN" });
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    console.log("*** response", response["data"]["body"]["token"]);
    const decoded = jwt_decode(response["data"]["body"]["token"]);
    if (decoded["role"] !== "admin") {
      EventBus.publish("error", "Can't login through User account ");
      return;
    }
    console.log("*** decoded", decoded);
    yield put(setLoginData(response['data']['body']["token"]));
    yield put(setAddress(decoded['publicAddress']));
    yield put({ type: "TOGGLE_LOGIN" });
    EventBus.publish("success", response['data']['message'])
    // setTimeout(() => history.push('/home'), 1000);
  }
};


/*========== DEPLOY CONTRACT FUNCTIONS ============= */

function* deployLottery({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/v1/deployLottery",
    payload,
  });
  if (error) {
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put({
      type: "GET_DONATIONS",
      payload: {
        owner: payload["owner"],
      },
    });
  }
}

function* getLottery({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/v1/getLottery",
    payload,
  });
  if (error) {
    // EventBus.publish("error", error["response"]["data"]["message"]);
  }
  else if (response) {
    console.log("*** getDonations response :: ", response["data"]["body"]);
    if (response["data"]["body"].length > 0) {
      yield put(setLottery(response["data"]["body"]));
    }
  }
}

function* getKybStatus({ payload }) {
  const { error, response } = yield call(postCall, { path: '/v3/getKybStatus', payload });
  if (error) {
    // EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(setKybStatus(response['data']['body']['kybStatus'] == "approved" ? true : false));
  }
};

export function* getUriData({ payload }) {
  const { error, response } = yield call(postCall, { path: "/getUriData", payload });
  if (response) {
    yield put(setUriData(response['data']['body']));
  }
};

function* actionWatcher() {
  yield takeEvery('LOGIN', login);
  yield takeEvery('DEPLOY_LOTTERY', deployLottery);
  yield takeEvery('GET_LOTTERY', getLottery);
  yield takeEvery('GET_KYB_STATUS', getKybStatus);
  yield takeEvery('GET_URI_DATA', getUriData);

};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
