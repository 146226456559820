import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { web3 } from "../../store/web3.js";
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { LotteryABI } from "../../store/contract/index.js";
import { setLoader, getLottery } from "../../store/actions/Auth.js";
import copyicon from "../../assets/img/copy.png";

import './index.css';
import 'react-responsive-modal/styles.css';

const WinnersList = (props) => {

  let [lotteryData, setlotteryData] = useState({});

  useEffect(() => {
    if (props.address) {
      props.getLottery({ owner: props.address });
    }
  }, [props.address]);

  useEffect(() => {
    if (web3 && props.address && props.deployedLottery && props.deployedLottery.length > 0) {
      sortLottery();
    }
  }, [props.deployedLottery, web3, props.address]);

  async function sortLottery() {
    try {
      let { deployedLottery } = props;
      props.setLoader({
        message: "Loading Lottery Data...",
        status: true,
      });
      let data = deployedLottery[0];
      let winnerData = [];
      let ownerData = [];
      let lotteryContract = new web3.eth.Contract(LotteryABI, data['lotteryAddress']);
      lotteryContract.events.LotteryWon({ fromBlock: 0 }, function (error, event) {
        let data = { transactionHash: event['transactionHash'], ...event['returnValues'] }
        winnerData.push(data);
      });
      lotteryContract.events.FoundersCut({ fromBlock: 0 }, function (error, event) {
        let data = { transactionHash: event['transactionHash'], ...event['returnValues'] }
        ownerData.push(data);
      });
      setlotteryData({ ...data, winnerData, ownerData });
      props.setLoader({
        message: "Loading Lottery Data...",
        status: false,
      });
    } catch (error) {
      props.setLoader({
        message: "Loading Lottery Data...",
        status: false,
      });
    }
  }

  console.log("*** lotteryData :: ", lotteryData);

  return (
    <div className="content">
      <div className="main-container mint-new">
        <div className="customer-table">
          <table cellpadding="0" cellspacing="0">
            <thead>
              <tr>
                {/* <th id="Name">Name</th> */}
                <th id="fullAddress">Address</th>
                <th id="status">Win Amount</th>
                {/* <th id="status">Draw date</th> */}
              </tr>
            </thead>
            <tbody>
              {
                lotteryData && Object.keys(lotteryData).length > 0 &&
                <>
                  {
                    lotteryData['winnerData'] && lotteryData['winnerData'].length > 0 && lotteryData['winnerData'].map((item) => (
                      <tr>
                        <td>
                          <a href={`https://xdc.blocksscan.io/address/${item['to']}`} target="_blank">
                            {item['to'] && item['to'].substring(0, 5) + '...' + item['to'].substring(37, item['to'].length)}
                            <img className="address" src="/images/share-direct.png" />
                          </a>
                        </td>

                        <td>{web3.utils.fromWei(item['reward'].toString(), 'ether')}</td>
                      </tr>
                    ))
                  }
                  {
                    lotteryData['ownerData'] && lotteryData['ownerData'].length > 0 && lotteryData['ownerData'].map((item) => (
                      <tr>
                        <td>
                          <a href={`https://xdc.blocksscan.io/address/${item['to']}`} target="_blank">
                            {item['to'] && item['to'].substring(0, 5) + '...' + item['to'].substring(37, item['to'].length)}
                            <img className="address" src="/images/share-direct.png" />
                          </a>
                        </td>
                        <td>{web3.utils.fromWei(item['reward'].toString(), 'ether')}</td>
                      </tr>
                    ))
                  }
                </>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

}

const mapDispatchToProps = {
  setLoader,
  getLottery
};

const mapStateToProps = ({ Auth }) => {
  let { address, deployedLottery } = Auth;
  return { address, deployedLottery };
};
export default connect(mapStateToProps, mapDispatchToProps)(WinnersList);