import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { web3 } from "../../store/web3.js";
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { LotteryABI } from "../../store/contract/index.js";
import { setLoader, getLottery } from "../../store/actions/Auth.js";
import copyicon from "../../assets/img/copy.png";

import './index.css';
import 'react-responsive-modal/styles.css';

const Profile = (props) => {

  let [lotteryData, setlotteryData] = useState({});

  useEffect(() => {
    if (props.address) {
      props.getLottery({ owner: props.address });
    }
  }, [props.address]);

  useEffect(() => {
    if (web3 && props.address && props.deployedLottery && props.deployedLottery.length > 0) {
      sortLottery();
    }
  }, [props.deployedLottery, web3, props.address]);

  async function sortLottery() {
    try {
      let { deployedLottery } = props;
      props.setLoader({
        message: "Loading Lottery Data...",
        status: true,
      });
      let data = deployedLottery[0];
      let winnerData = [];
      let ownerData = [];
      let lotteryContract = new web3.eth.Contract(LotteryABI, data['lotteryAddress']);
      lotteryContract.events.LotteryWon({ fromBlock: 0 }, function (error, event) {
        let data = { transactionHash: event['transactionHash'], ...event['returnValues'] }
        winnerData.push(data);
      });
      lotteryContract.events.FoundersCut({ fromBlock: 0 }, function (error, event) {
        let data = { transactionHash: event['transactionHash'], ...event['returnValues'] }
        ownerData.push(data);
      });
      setlotteryData({ ...data, winnerData, ownerData });
      props.setLoader({
        message: "Loading Lottery Data...",
        status: false,
      });
    } catch (error) {
      props.setLoader({
        message: "Loading Lottery Data...",
        status: false,
      });
    }
  }

  console.log("*** lotteryData :: ", lotteryData);

  return (
    <div className="content">
      <div className="profile">
        <h1>NFTs</h1>

        <div className="cards">
          <div className="card">
              <img src="/images/lottery.png"/>
              <h3>Title</h3>
              <p>This is paragraph</p>
              <span>Specific</span>
          </div>

          <div className="card">
              <img src="/images/lottery.png"/>
              <h3>Title</h3>
              <p>This is paragraph</p>
              <span>Specific</span>
          </div>

          <div className="card">
              <img src="/images/lottery.png"/>
              <h3>Title</h3>
              <p>This is paragraph</p>
              <span>Specific</span>
          </div>

          <div className="card">
              <img src="/images/lottery.png"/>
              <h3>Title</h3>
              <p>This is paragraph</p>
              <span>Specific</span>
          </div>
        </div>
      </div>
    </div>
  );

}

const mapDispatchToProps = {
  setLoader,
  getLottery
};

const mapStateToProps = ({ Auth }) => {
  let { address, deployedLottery } = Auth;
  return { address, deployedLottery };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);